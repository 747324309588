.App {
    min-height: 100vh;
    background-color: #000000;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}

.App-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10%;
    padding-bottom: 50px;
    color: white;
}

.App-text {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    color: white;
}

.text {
    padding-right: 35%;
    color: white;
}

.text-privacy {
    line-height: 1.8;
    color: white;
}

.App-link {
    color: #61dafb;
}

.center {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.centerChild {
    color: #61dafb;
    flex-basis: content;
    margin: 5px;
}

.footer-link {
    background-color: #000000;
    position: fixed;
    bottom: 0;
    padding-bottom: 100px;
    width: 100%;
    color: #61dafb;
    text-align: center;
}

.footer {
    background-color: #000000;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: darkgrey;
    text-align: left;
    padding-left: 25px;
}
